<template>
  <div class="event-card">
    <router-link 
      class="event-card__link" 
      :to="{ name: 'EventDetails', params: { id: event.id } }"
    >
      <span> @ {{ event.time }} on {{ event.date }} </span>
      <h4>{{ event.title }}</h4>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "EventCard",
  props: {
    event: Object
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .event-card {
    padding: 20px;
    width: 250px;
    cursor: pointer;
    border: 1px solid #39495c;
    margin-bottom: 18px;
  }

  .event-card__link {
    color: #2c3e50;
    text-decoration: none;
  }

  .event-card:hover {
    transform: scale(1.01);
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
  }
</style>
